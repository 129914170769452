// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import SmartBanner from "smart-app-banner";

require("jquery")
require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "controllers"

require("underscore/underscore")
require('bootstrap/dist/js/bootstrap')
require('lightslider/dist/js/lightslider')
require('select2/dist/js/select2.full')
require('smart-app-banner/dist/smart-app-banner')

import '@doabit/semantic-ui-sass'
require('semantic-ui/calendar')

import moment from 'moment';
window.moment = moment;

import $ from 'jquery';
window.jQuery = $; window.$ = $;

import 'jquery-creditcardvalidator/jquery.creditCardValidator'

import _ from 'underscore';
window._ = _;

import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
window.Swiper = Swiper;
Swiper.use([Navigation, Pagination, Autoplay]);

import modernizr from 'modernizr';

import Swal from 'sweetalert2'
window.Swal = Swal;

require('ion-rangeslider/js/ion.rangeSlider')
require('owl.carousel/dist/owl.carousel')

import * as geolib from 'geolib';
window.geolib = geolib

// Scripts del diseñador
require('mn')

// Bibliotecas y códigos MotelNow
import MotelNow from 'motel_now';
window.MotelNow = MotelNow;

window.moment.locale(MotelNow.config.locale);


$(function () {
  //console.log('Hello World from Webpacker');
  $("[data-toggle='tooltip']").tooltip({container: 'body'});
  $("[data-toggle='popover']").popover({container: 'body'});

  new SmartBanner({
    daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
    daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
    appStoreLanguage: 'es', // language code for the App Store (defaults to user's browser language)
    title: 'MotelNow',
    author: 'Grupo now SpA',
    button: 'Ver',
    store: {
      ios: 'En App Store',
      android: 'En Google Play'
    },
    price: {
      ios: 'OBTENER',
      android: 'OBTENER'
    }
    //, theme: 'ios' // put platform type ('ios', 'android', etc.) here to force single theme on all device
    // , icon: '' // full path to icon image if not using website icon image
    //, force: 'windows' // Uncomment for platform emulation
  });
})

import intlTelInput from 'intl-tel-input';
window.intlTelInput = intlTelInput;

import parameterize from "parameterize";

import noUiSlider from "nouislider";
window.noUiSlider = noUiSlider;

import 'bootstrap-icons/font/bootstrap-icons.css'